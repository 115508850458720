import React from "react";
import PropTypes from "prop-types";

import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/i18n/swedish";

import surveyTheme from "@assets/survey_theme.json";

import Config from "@src/config";

const SurveyForm = ({
  data,
  formConfig,
  mode = "edit",
  onComplete,
  onRefLoaded,
}) => {
  const survey = new Model(formConfig);
  survey.applyTheme(surveyTheme);
  survey.onComplete.add(onComplete);
  survey.locale = Config.language;
  survey.showCompleteButton = false;
  survey.data = { ...survey.data, ...data };
  survey.mode = mode;

  // Attach onAfterRenderPanel event
  survey.onAfterRenderPanel.add((survey, options) => {
    // Handle sub-panels within the main panel
    const button = options.htmlElement.querySelector(".btn-custom-event");

    if (button) {
      const handleButtonClick = () => {
        const expression = button.getAttribute("data-expression");
        const type = button.getAttribute("data-type") || "paneldynamic";
        const panelIndex = Number(button.getAttribute("data-panel-index")) - 1;

        if (type === "paneldynamic") {
          // Parse expression with index for dynamic panels
          // eslint-disable-next-line no-console
          console.log(expression);
          const expressionMatch = expression.match(/^(\w+)\[(\d+)\]\.(\w+)\s*=\s*(\w+)\[(\d+)\]\.(\w+)$/);
          if (!expressionMatch) {
            return;
          }

          const targetObject = expressionMatch[1];
          const targetKey = expressionMatch[3];
          const sourceObject = expressionMatch[4];
          const sourceKey = expressionMatch[6];

          const sourceValue = survey.data[sourceObject][panelIndex][sourceKey];
          survey.data[targetObject][panelIndex][targetKey] = sourceValue;

        } else {
          // Non-dynamic panel case
          const [targetField, sourceField] = expression.split("=");

          const sourceValue = survey.data[sourceField.trim()];
          survey.data[targetField.trim()] = sourceValue;

          // Update survey to reflect changes
          survey.setValue(targetField.trim(), sourceValue);
        }
      };
      button.removeEventListener("click", handleButtonClick);
      button.addEventListener("click", handleButtonClick);
    }
  });

  onRefLoaded?.(survey);

  return <Survey model={survey} />;
};

SurveyForm.propTypes = {
  data: PropTypes.object,
  formConfig: PropTypes.object,
  mode: PropTypes.string,
  onComplete: PropTypes.func,
  onRefLoaded: PropTypes.func,
};

export default SurveyForm;
