import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Formik, Form } from "formik";
import styled from "styled-components";
import * as Yup from "yup";

import getDataSources from "@app/services/products/getDataSources";
import createNewProduct from "@app/services/services/createNewProduct";
import fetchAllProducts from "@app/services/services/fetchProductById";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import JsonForm from "@components/JsonForm/JsonForm";
import ProgressSpinner from "@components/ProgressSpinner";

import { useToast } from "@hooks/useToast";

import CardWrapper from "@pages/admin/users/components/CardWrapper";

import { COURT_PACKAGE_OPTIONS, BUNDLE, ANALYSIS } from "@utils/constant";
import { SERVICE_TYPE } from "@utils/enum";

const StyledCreateBilling = styled.div`
  max-width: 1110px;
`;

const EditServiceDialog = ({ handleClose }) => {
  const { messages } = useIntl();
  const { showSuccessToast, showErrorToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [dataSourceTypes, setDataSourceTypes] = useState([]);
  const [servicesInBundle, setServicesInBundle] = useState([]);

  useEffect(() => {
    const fetchDataSources = async () => {
      setIsLoading(true);

      try {
        const { data: response = [] } = await getDataSources();
        const translatedOptions = response.map(option => ({
          label: messages[`datasource_${option}`] ?? "",
          value: option,
        }));

        setDataSourceTypes(translatedOptions);
      } catch (e) {
        showErrorToast(messages.error_data_source_values);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDataSources();
  }, [messages, showErrorToast]);

  useEffect(() => {
    const fetchServicesInBundle = async () => {
      try {
        const servicesResponse = await fetchAllProducts();

        if (!Array.isArray(servicesResponse.data.data)) {
          showErrorToast(messages.text_no_data_found);
        }

        const filteredServices = servicesResponse.data.data.map(obj => ({
          label: obj?.name,
          value: obj?.id,
        }));

        setServicesInBundle(filteredServices);
      } catch (error) {
        showErrorToast(messages.error_service_in_bundle);
      } finally {
        setIsLoading(false);
      }
    };

    fetchServicesInBundle();
  }, [messages, showErrorToast]);

  const SERVICE_TYPE_OPTIONS = [
    {
      value: SERVICE_TYPE.ANALYSIS,
      label: messages[SERVICE_TYPE.ANALYSIS],
    },
    {
      value: SERVICE_TYPE.ASSIGNMENT,
      label: messages[SERVICE_TYPE.ASSIGNMENT],
    },
    {
      value: SERVICE_TYPE.BUNDLE,
      label: messages.servicetype_bundle,
    },
  ];

  const analysisTypes = [
    {
      label: messages.label_general_analysis,
      value: "general-analysis",
    },
    {
      label: messages.label_role_analysis,
      value: "role-specific-analysis",
    },
    {
      label: messages.label_general_analysis_actapublic,
      value: "general-analysis-actapublica",
    },
  ];
  const courtPackageOptions = [
    {
      label: messages.datasource_court_small,
      value: COURT_PACKAGE_OPTIONS.SMALL,
    },
    {
      label: messages.datasource_court_medium,
      value: COURT_PACKAGE_OPTIONS.MEDIUM,
    },
    {
      label: messages.datasource_court_large,
      value: COURT_PACKAGE_OPTIONS.LARGE,
    },
  ];
  const processtypesOptions = [
    {
      label: messages.label_automatic,
      value: "automatic",
    },
    {
      label: messages.label_manual,
      value: "manual",
    },
  ];
  const fortnoxOptions = [
    {
      label: messages.yes_label,
      value: 1,
    },
    {
      label: messages.no_label,
      value: 0,
    },
  ];
  const caseOptions = [
    {
      label: messages.yes_label,
      value: 1,
    },
    {
      label: messages.no_label,
      value: 0,
    },
  ];
  const selectOptions = [
    {
      label: messages.yes_label,
      value: 1,
    },
    {
      label: messages.no_label,
      value: 0,
    },
  ];
  const availableOptions = [
    {
      label: messages.yes_label,
      value: 1,
    },
    {
      label: messages.no_label,
      value: 0,
    },
  ];
  const productInformation = [
    {
      name: "name",
      label: messages.label_service_name,
      fieldType: "text",
      validation: Yup.string().required(messages.error_product_name),
    },
    {
      name: "price",
      label: messages.watchlist_label_price,
      fieldType: "text",
      validation: Yup.string().required(messages.error_price_validation),
    },
    {
      name: "delivery_time",
      label: messages.delivery_time_label,
      fieldType: "text",
      validation: Yup.string().required(messages.error_delivery_time),
    },
    {
      name: "listing_order",
      label: messages.label_listing_order,
      fieldType: "text",
      validation: Yup.number()
        .typeError(messages.error_listing_order)
        .integer(messages.error_listing_order_type)
        .min(1, messages.error_listing_order_min_number)
        .max(999, messages.error_listing_order_max_number),
    },
    {
      name: "sku",
      label: messages.label_sku,
      fieldType: "text",
    },
    {
      name: "default_selected",
      label: messages.label_default_selected,
      fieldType: "dropdown",
      options: selectOptions,
      validation: Yup.boolean(),
    },
    {
      name: "default_available",
      label: messages.label_default_availability,
      fieldType: "dropdown",
      options: availableOptions,
      validation: Yup.boolean(),
    },
  ];
  const descInformation = [
    {
      name: "description",
      fieldType: "textarea",
      validation: Yup.string().required(messages.error_product_description),
    },
  ];
  const configurationInformation = [
    {
      name: "service_type",
      label: messages.label_product_type_form,
      fieldType: "dropdown",
      options: SERVICE_TYPE_OPTIONS,
      values: "service_type",
      validation: Yup.string().required(messages.error_service_type),
    },
    {
      name: "analysis_type",
      label: messages.label_analysis_type,
      fieldType: "dropdown",
      options: analysisTypes,
      show: values => values.service_type === SERVICE_TYPE.ANALYSIS,
      values: "analysis_type",
      validation: Yup.string().when("service_type", {
        is: serviceType => {
          return serviceType === "analysis";
        },
        then: () => Yup.string().required(messages.error_analysis_type),
        otherwise: () => Yup.string(),
      }),
    },
    {
      name: "data_source",
      label: messages.label_data_source,
      fieldType: "multiselect",
      options: dataSourceTypes || [],
      show: values => values.service_type === SERVICE_TYPE.ANALYSIS,
      onChange: { setDataSourceTypes },
      validation: Yup.array(),
    },
    {
      name: "child",
      label: messages.label_services_in_bundle,
      fieldType: "multiselect",
      options: servicesInBundle,
      show: values => values.service_type === SERVICE_TYPE.BUNDLE,
      validation: Yup.array().when("service_type", {
        is: serviceType => serviceType === "bundle",
        then: () =>
          Yup.array()
            .required(messages.error_service_in_bundle)
            .min(1, messages.error_service_in_bundle),
        otherwise: () => Yup.array(),
      }),
    },
    {
      label: messages.court_package,
      fieldType: "dropdown",
      name: "court_package",
      placeholder: messages.court_package,
      options: courtPackageOptions,
      show: values =>
        values.data_source?.includes("court") &&
        values.service_type === SERVICE_TYPE.ANALYSIS,
      value: "court_package",
      validation: Yup.string().required(messages.label_required),
    },
    {
      label: messages.label_process_type,
      fieldType: "dropdown",
      name: "process_type",
      placeholder: messages.label_process_type,
      options: processtypesOptions,
      value: "process_type",
      validation: Yup.string().required(messages.label_required),
    },
    {
      label: messages.label_fortnox_article_number,
      fieldType: "text",
      name: "fortnox_article_number",
      placeholder: messages.label_fortnox_article_number,
      options: fortnoxOptions,
      value: "fortnox_article_number",
    },
    {
      label: messages.label_case,
      fieldType: "dropdown",
      name: "is_case",
      placeholder: messages.label_case,
      options: caseOptions,
      value: "is_case",
      validation: Yup.string().required(messages.label_required),
    },
    {
      label: messages.label_report,
      fieldType: "dropdown",
      name: "is_report",
      placeholder: messages.label_report,
      options: caseOptions,
      value: "is_report",
      validation: Yup.string().required(messages.label_required),
    },
  ];

  const initialValues = {
    service_type: "",
    analysis_type: "",
    data_source: [],
    child: [],
    name: "",
    price: "",
    delivery_time: "",
    listing_order: null,
    sku: "",
    description: "",
    default_selected: false,
    default_available: false,
    purchase_by_credits: false,
    price_credits: 0,
    state: "active",
    type: "service",
  };

  const formLayout = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gridColumnGap: 2,
  };

  const handleFormSubmit = async values => {
    setIsLoading(true);

    let payload;
    const {
      data_source,
      analysis_type,
      child,
      service_type,
      ...otherProperties
    } = values;

    if (service_type === BUNDLE) {
      payload = {
        ...otherProperties,
        service_type,
        child: child.map(o => {
          const service = servicesInBundle.find(service => service.label === o);
          return service ? service.value : o;
        }),
      };
    } else if (service_type === ANALYSIS) {
      payload = {
        ...otherProperties,
        service_type,
        data_source: data_source.map(source => source),
        analysis_type,
      };
    } else {
      payload = { ...otherProperties, service_type, analysis_type: "manual" };
    }
    
    try {
      await createNewProduct(payload);

      showSuccessToast(messages.text_update_successful);

      if (handleClose) {
        handleClose();
      }
    } catch (e) {
      showErrorToast(messages.text_no_data_found);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <FullSizeDialog
        title={messages.title_create_service}
        onClose={handleClose}
      >
        <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
          {({ isSubmitting, dirty, isValid }) => (
            <StyledCreateBilling>
              <Form>
                <CardWrapper title={messages.heading_basic_information}>
                  <JsonForm
                    config={productInformation}
                    formLayout={formLayout}
                  />
                </CardWrapper>

                <CardWrapper title={messages.title_product_description}>
                  <JsonForm config={descInformation} formLayout={formLayout} />
                </CardWrapper>

                <CardWrapper title={messages.title_billing}>
                  <JsonForm
                    config={configurationInformation}
                    formLayout={formLayout}
                  />
                </CardWrapper>

                <Div
                  display="flex"
                  flexDirection={["column", "column", "row", "row"]}
                  alignItems="center"
                  justifyContent="center"
                  gridGap={4}
                  mt={4}
                >
                  <PrimaryButtonOutlined
                    width={[1, 1, "150px", "150px"]}
                    label={messages.label_cancel}
                    onClick={handleClose}
                  />
                  <PrimaryButton
                    width={[1, 1, "150px", "150px"]}
                    label={messages.label_save}
                    type="submit"
                    disabled={!dirty || isSubmitting || !isValid}
                  />
                </Div>
              </Form>
            </StyledCreateBilling>
          )}
        </Formik>
      </FullSizeDialog>
    </Div>
  );
};
EditServiceDialog.propTypes = {
  handleClose: PropTypes.func,
};
export default EditServiceDialog;
