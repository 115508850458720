import React from "react";
import { FormattedMessage } from "react-intl";

const ContentOptions = [
  {
    label: <FormattedMessage id="admin_customer_details_content_cv" />,
    value: "cv",
  },
  {
    label: <FormattedMessage id="admin_customer_details_content_non_disclosure" />,
    value: "non_disclosure",
  },
  {
    label: <FormattedMessage id="admin_customer_details_content_certificate_of_education" />,
    value: "certificate_of_education",
  },
  {
    label: <FormattedMessage id="admin_customer_details_content_background_analysis" />,
    value: "background_analysis",
  },
  {
    label: <FormattedMessage id="admin_customer_details_content_credit_information" />,
    value: "credit_information",
  },
  {
    label: <FormattedMessage id="admin_customer_details_content_interview" />,
    value: "interview",
  },
  {
    label: <FormattedMessage id="admin_customer_details_content_other" />,
    value: "other",
  },
];

export default ContentOptions;